<template>
  <el-menu
    :default-active="$route.path"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    @open="handleOpen"
    @close="handleClose"
    
    text-color="#000"
    router
  >
    <div class="sidebar-logo-container collapse">
     <!--  <img src="@/assets/logo.png" class="sidebar-logo" /> -->
      <h1 class="sidebar-title">水产品信息录入系统</h1>
    </div>
     <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
  </el-menu>
  
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SidebarItem from './SidebarItem'
import {constantRoutes} from "@/router" //导入路由
//import  { useRoute } from "vue-router"

    // eslint-disable-next-line no-unused-vars
    //const route = useRoute()
    let routes = computed(() => {
    
    return constantRoutes
})

    const store = useStore();
    const isCollapse = computed(() => {
      return store.state.app.isCollapse;
    });
    const handleOpen = (key, keyPath) => {
      console.log(key, keyPath);
    };
    const handleClose = (key, keyPath) => {
      console.log(key, keyPath);
    };
</script>

<style lang="scss" scope>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  height: 100vh;
}
.el-menu{
  border-right:solid 0px;
 /*  background: linear-gradient(80deg, rgb(255, 238, 6), rgb(255, 1, 1));
  background: linear-gradient(80deg, rgb(57,213,119), rgb(27, 197, 142));
  background: linear-gradient(80deg, rgb(6, 150, 104), rgb(7, 138, 57));
  background: linear-gradient(20deg, rgb(18,106,215), rgb(18, 106, 215)); */

}
.el-menu--collapse {
  height: 100vh;
}

.sidebar-logo-container {
  position: relative;
  width: auto;
  height: 58px;
  line-height: 50px;
  background: #191A23;
 /*  background: linear-gradient(80deg, rgb(255, 238, 6), rgb(255, 1, 1));
   background: linear-gradient(80deg, rgb(57,213,119), rgb(27, 197, 142));
   background: linear-gradient(80deg, rgb(6, 150, 104), rgb(7, 138, 57)); */
   background: rgb(18, 106, 215);
  padding-left: 20px;
  text-align: left;
  overflow: hidden;
}

.sidebar-logo {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 12px;
}

.sidebar-title {
  padding-left: 10px;
  display: inline-block;
  margin: 0;
  color: #ffffff;
  font-weight: 600;
  line-height: 50px;
  font-size: 14px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
  text-align: center;
}
</style>